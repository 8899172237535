<template>
  <div class="text-ellipsis">
    <el-tooltip placement="top" ref="tooltip" :content="this.msg" :disabled="isShowToolTip" :tabindex="99">
      <div ref="textEllipsisDiv" class="text-ellipsis-div" :style="{'width':`${width}`}">{{msg}}</div>
    </el-tooltip>
  </div>
</template>
<script>
  import {getStyle} from "element-ui/src/utils/dom";

  export default {
    name:'textEllipsis',
    props:{
      msg:{
        type:[String,Number],
        default: ''
      },
      position:{
        type: String,
        default: 'top'
      },
      width:[Number,String]
    },
    data(){
      return {
        isShowToolTip: true,
      }
    },
    created() {

    },
    mounted() {
      this.$nextTick(()=>{
        this.init()
      })
    },
    methods:{
      init(){
        this.isShowToolTip = true
        const textEllipsisDiv = this.$refs.textEllipsisDiv
        const range = document.createRange();
        range.setStart(textEllipsisDiv, 0);
        range.setEnd(textEllipsisDiv, textEllipsisDiv.childNodes.length);
        const rangeWidth = range.getBoundingClientRect().width;
        const padding = (parseInt(getStyle(textEllipsisDiv, 'paddingLeft'), 10) || 0) +
          (parseInt(getStyle(textEllipsisDiv, 'paddingRight'), 10) || 0);
        if ((rangeWidth + padding > textEllipsisDiv.offsetWidth || textEllipsisDiv.scrollWidth > textEllipsisDiv.offsetWidth) && !!this.$refs.tooltip) {
          this.isShowToolTip = false
        }
      }
    },
    watch:{
      width(){
        this.init()
      }
    }
  }
</script>

<style lang="scss">
  .text-ellipsis{
    .text-ellipsis-div{
      padding-right: 10px;
      width: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: right;
      user-select: none;
    }
  }

</style>